<div class="govuk-accordion__section" [class.govuk-accordion__section--expanded]="isExpanded$ | async">
  <div
    class="govuk-accordion__section-header"
    [class.govuk-accordion__section-header--focused]="isFocused"
    (click)="isExpanded.next(!isExpanded.value)"
    (keydown)="isExpanded.next(!isExpanded.value)"
    tabindex="0"
  >
    <h2 class="govuk-accordion__section-heading">
      <button
        type="button"
        class="govuk-accordion__section-button"
        [id]="headingId"
        [attr.aria-controls]="contentId"
        [attr.aria-describedby]="summary ? summaryId : null"
        [attr.aria-expanded]="isExpanded$ | async"
        (focusin)="isFocused = true"
        (blur)="isFocused = false"
      >
        <span class="govuk-accordion__section-heading-text" [id]="headingId">
          <span class="govuk-accordion__section-heading-text-focus">{{ header }}</span>
        </span>
        <ng-container *ngIf="summary">
          <span class="govuk-visually-hidden govuk-accordion__section-heading-divider">, </span>
          <span class="govuk-accordion__section-summary govuk-body" [id]="summaryId">
            <span class="govuk-accordion__section-summary-focus">{{ summary }}</span>
          </span>
        </ng-container>
        <span class="govuk-visually-hidden govuk-accordion__section-heading-divider">, </span>
        <span class="govuk-accordion__section-toggle">
          <span class="govuk-accordion__section-toggle-focus">
            <span
              class="govuk-accordion-nav__chevron"
              [class.govuk-accordion-nav__chevron--down]="(isExpanded$ | async) === false"
            ></span>
            <span class="govuk-accordion__section-toggle-text">{{ (isExpanded$ | async) ? 'Hide' : 'Show' }}</span>
          </span>
        </span>
      </button>
    </h2>
  </div>
  <div
    [id]="contentId"
    [attr.aria-labelledby]="headingId"
    class="govuk-accordion__section-content"
    [hidden]="(isExpanded$ | async) ? null : 'until-found'"
  >
    <ng-content></ng-content>
  </div>
</div>
