<ng-container *ngIf="cookiesNotAccepted()">
  <div id="global-cookie-message-to-accept" class="gem-c-cookie-banner" role="region" aria-label="cookie banner">
    <div class="govuk-width-container">
      <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
          <div>
            <h2 class="govuk-heading-m">Tell us whether you accept cookies</h2>
            <p class="govuk-body">
              We use cookies to collect information about how you use GOV.UK. We use this information to make the
              website work as well as possible and improve government services.
            </p>
          </div>
          <div>
            <div class="govuk-grid-column-one-half-from-desktop">
              <button
                type="button"
                class="govuk-button govuk-cookie-button"
                (click)="acceptCookies()"
                [disabled]="!areBrowserCookiesEnabled"
              >
                Accept all cookies
              </button>
            </div>
            <div class="govuk-grid-column-one-half-from-desktop">
              <button
                type="button"
                class="govuk-button govuk-cookie-button"
                [disabled]="!areBrowserCookiesEnabled"
                (click)="goToSetPreferences()"
              >
                Set cookie preferences
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="show">
  <div id="global-cookie-message-approval" class="gem-c-cookie-banner govuk-clearfix" aria-label="cookie banner">
    <div class="govuk-width-container">
      <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
          <p>
            You’ve accepted all cookies. You can
            <a class="govuk-link" [routerLink]="[]" (click)="goToSetPreferences()">change your cookie settings</a>
            at any time.
          </p>
        </div>
        <div class="govuk-grid-column-one-third">
          <p>
            <button type="button" class="gem-c-cookie-banner__hide-button" (click)="hideCookieMessage()">Hide</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
