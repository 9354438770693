<div *ngIf="currentPage$ | async as page" class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <nav *ngIf="totalPages > 1" class="govuk-pagination" aria-label="Pagination">
      <div *ngIf="page > 1" class="govuk-pagination__prev">
        <a
          class="govuk-link govuk-pagination__link"
          [fragment]="route.fragment | async"
          [queryParams]="{ page: page > 2 ? page - 1 : 1 }"
          [state]="{ scrollSkip: true }"
          queryParamsHandling="merge"
          [routerLink]="[]"
          rel="prev"
        >
          <svg
            class="govuk-pagination__icon govuk-pagination__icon--prev"
            xmlns="http://www.w3.org/2000/svg"
            height="13"
            width="15"
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 15 13"
          >
            <path
              d="m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z"
            ></path>
          </svg>
          <span class="govuk-pagination__link-title"> Previous<span class="govuk-visually-hidden"> page</span> </span>
        </a>
      </div>
      <ul *ngIf="!hideNumbers" class="govuk-pagination__list">
        <ng-container *ngFor="let pageNumber of pageNumbers; first as isFirst; last as isLast">
          <li
            *ngIf="isFirst || isLast || isDisplayed(pageNumber, page)"
            class="govuk-pagination__item"
            [class.govuk-pagination__item--current]="page === pageNumber"
          >
            <a
              class="govuk-link govuk-pagination__link"
              [fragment]="route.fragment | async"
              [queryParams]="{ page: pageNumber }"
              [state]="{ scrollSkip: true }"
              queryParamsHandling="merge"
              [routerLink]="[]"
              [attr.aria-label]="'Page ' + pageNumber"
              [attr.aria-current]="page === pageNumber ? 'page' : null"
            >
              {{ pageNumber }}
            </a>
          </li>
          <li *ngIf="isDots(pageNumber, page)" class="govuk-pagination__item govuk-pagination__item--ellipses">
            &ctdot;
          </li>
        </ng-container>
      </ul>
      <div *ngIf="page !== pageNumbers.length" class="govuk-pagination__next">
        <a
          class="govuk-link govuk-pagination__link"
          [fragment]="route.fragment | async"
          [queryParams]="{ page: page < totalPages ? page + 1 : totalPages }"
          [state]="{ scrollSkip: true }"
          queryParamsHandling="merge"
          [routerLink]="[]"
          rel="next"
        >
          <span class="govuk-pagination__link-title"> Next<span class="govuk-visually-hidden"> page</span> </span>
          <svg
            class="govuk-pagination__icon govuk-pagination__icon--next"
            xmlns="http://www.w3.org/2000/svg"
            height="13"
            width="15"
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 15 13"
          >
            <path
              d="m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z"
            ></path>
          </svg>
        </a>
      </div>
    </nav>
  </div>
  <div class="govuk-grid-column-one-third">
    <p
      *ngIf="!hideResultCount && count"
      class="govuk-body govuk-!-margin-top-2 govuk-!-margin-bottom-2 govuk-!-text-align-right"
    >
      Showing <strong>{{ (page - 1) * pageSize + 1 }}</strong> to
      <strong>{{ page * pageSize < count ? page * pageSize : count }}</strong> of
      <strong>{{ count }}</strong>
      results
    </p>
  </div>
</div>
