<label [class.govuk-visually-hidden]="isLabelHidden" [for]="identifier" [id]="'l.' + identifier" class="govuk-label">
  {{ currentLabel }}
</label>
<govuk-error-message *ngIf="shouldDisplayErrors" [errors]="control.errors" [identifier]="identifier">
</govuk-error-message>
<select
  [formControl]="control"
  [id]="identifier"
  [attr.name]="identifier"
  [ngClass]="widthClass"
  [class.govuk-select--error]="shouldDisplayErrors"
  class="govuk-select"
  govukFormError
>
  <option *ngFor="let option of options" [attr.disabled]="option.disabled || null" [ngValue]="option.value">
    {{ option.text }}
  </option>
  <ng-content></ng-content>
</select>
