<p class="govuk-body">
  A responsible officer is an individual from one of the organisations covered by the notification who is either:
</p>

<ul class="govuk-list govuk-list--bullet">
  <li>a director who has been nominated for the purpose of ensuring ESOS compliance</li>
  <li>an individual who has management control, in the absence of a director</li>
</ul>

<p class="govuk-body">
  You must confirm that all of these statements are true before you can submit your notification of compliance.
</p>

<h3 class="govuk-heading-s">
  I confirm that {{ isSecondOfficer ? 'a second' : 'the' }} responsible officer has reviewed the recommendations of the
  ESOS assessment and is satisfied that, to the best of their knowledge, the following statements are true:
</h3>

<div govuk-checkboxes [formControlName]="controlName">
  <govuk-checkbox
    [value]="'SATISFIED_WITH_ORGANISATION_WITHIN_SCOPE_OF_THE_SCHEME'"
    [label]="
      'SATISFIED_WITH_ORGANISATION_WITHIN_SCOPE_OF_THE_SCHEME' | energyAssessmentTypes : hasNoEnergyResponsibility
    "
  >
  </govuk-checkbox>
  <govuk-checkbox
    [value]="'SATISFIED_WITH_ORGANISATION_COMPLIANT_WITH_SCOPE_OF_THE_SCHEME'"
    [label]="'SATISFIED_WITH_ORGANISATION_COMPLIANT_WITH_SCOPE_OF_THE_SCHEME' | energyAssessmentTypes"
  >
  </govuk-checkbox>
  <govuk-checkbox
    [value]="'SATISFIED_WITH_INFORMATION_PROVIDED_UNLESS_THERE_IS_A_DECLARED_REASON'"
    [label]="'SATISFIED_WITH_INFORMATION_PROVIDED_UNLESS_THERE_IS_A_DECLARED_REASON' | energyAssessmentTypes"
  >
  </govuk-checkbox>
  <govuk-checkbox
    [value]="'SATISFIED_WITH_INFORMATION_PROVIDED'"
    [label]="'SATISFIED_WITH_INFORMATION_PROVIDED' | energyAssessmentTypes"
  >
  </govuk-checkbox>
</div>
