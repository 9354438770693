<div *ngIf="vm$ | async as vm">
  <div *ngIf="vm.isLoggedIn === false; else welcome">
    <div class="govuk-grid-row">
      <div class="govuk-grid-column-two-thirds">
        <govuk-notification-banner heading="Important">
          <p class="govuk-notification-banner__heading">
            You can now submit an action plan in MESOS. The Environment Agency will accept action plans submitted after
            the deadline (5 December 2024) provided this is done by 5 March 2025.
          </p>
          <p class="govuk-notification-banner__heading">
            Before you can submit your action plan, you are required to have submitted your notification of compliance.
            If this was not submitted by the deadline (6 August 2024), you may be at risk of enforcement action and
            should contact the Environment Agency immediately.
          </p>
          <p class="govuk-notification-banner__heading">
            You must ensure you respond promptly to any communications you receive from the Environment Agency.
          </p>
        </govuk-notification-banner>
        <esos-page-heading>Manage your Energy Savings Opportunity Scheme Reporting</esos-page-heading>
        <div class="govuk-body">
          <p>
            The Energy Savings Opportunity Scheme (ESOS) is an energy assessment and energy saving scheme that applies
            to large undertakings and groups containing large undertakings in the UK. This includes:
          </p>

          <ul class="govuk-list govuk-list--bullet govuk-!-margin-left-3">
            <li>corporate bodies or partnerships</li>
            <li>unincorporated associations carrying on a trade or business, with or without a view to profit</li>
            <li>partnerships and limited liability partnerships</li>
            <li>joint ventures</li>
          </ul>

          <h2 class="govuk-heading-m">Who qualifies for ESOS</h2>

          <p>
            You must take part in ESOS if your organisation, or any UK undertakings in your organisation’s group,
            qualifies as a large undertaking on the qualification date.
          </p>

          <p>The qualification date for the third compliance period is 31 December 2022.</p>

          <p>A large undertaking is any UK undertaking that meets one or both of the following conditions:</p>

          <ol class="govuk-list govuk-list--number govuk-list--spaced govuk-!-margin-left-3">
            <li>employs 250 or more people</li>

            <li>
              has an annual turnover in excess of £44 million and an annual balance sheet total in excess of £38 million
            </li>
          </ol>

          <h2 class="govuk-heading-m">How do I notify compliance with ESOS?</h2>

          <p>
            A representative from the responsible undertaking must set up a sign in to create an account on the online
            notification system and fill in the notification requirements. The representative can add an external lead
            assessor to the account to assist with filling in information, but only the responsible undertaking can
            submit the completed notification.
          </p>

          <p>
            Responsible undertakings are by default the highest UK parent in a group acting on behalf of a group of
            qualifying undertakings unless other agreements apply.
          </p>

          <p class="govuk-!-font-weight-bold">
            The wording in the online notification system is for information only and should be read in conjunction with
            the ESOS guidance on
            <a govukLink rel="noreferrer noopener" href="https://www.gov.uk"> gov.uk </a>
            and the regulations on ESOS. In the event of any inconsistency or doubt the regulations should be followed.
          </p>

          <h3 class="govuk-heading-s">Adding an external lead assessor</h3>

          <p>
            An external lead assessor can provide their email address to a responsible undertaking in order to be added
            to the responsible undertaking’s account. This generates an activation email containing a link which the
            lead assessor will use to set up a sign in to create their own account and add it to the responsible
            undertaking’s account.
          </p>

          <p>
            The lead assessor can also create their own account first, and provide the responsible undertaking with
            their email address in order to be added to the responsible undertaking’s account. The lead assessor will
            receive an activation email each time a new responsible undertaking adds the lead assessor to their account.
            Using the same sign in details, the lead assessor will be able to access the new responsible undertaking
            account, and those of all other responsible undertakings to which they have been added.
          </p>
          <br />

          <h3 class="govuk-heading-s">I already have a sign in</h3>
          <p>You'll need your password and two-factor authentication app to sign in to the service.</p>

          <a class="govuk-button govuk-button--start" routerLink="." (click)="authService.login()" draggable="false">
            Sign in
            <img class="govuk-button__start-icon" src="/assets/images/start-button-arrow.svg" alt="" />
          </a>
          <br />

          <h3 class="govuk-heading-s">I need to create a sign in</h3>
          <p>You can apply online for a sign in if you qualify for ESOS.</p>

          <a class="govuk-button govuk-button--start" routerLink="/registration" draggable="false">
            Create a sign in
            <img class="govuk-button__start-icon" src="/assets/images/start-button-arrow.svg" alt="" />
          </a>
        </div>
      </div>

      <div class="govuk-grid-column-one-third">
        <esos-related-content>
          <ul class="govuk-list govuk-!-font-size-16 govuk-list--spaced">
            <li>
              <a
                govukLink
                rel="noreferrer noopener"
                href="https://www.gov.uk/government/publications/comply-with-the-energy-savings-opportunity-scheme-esos/complying-with-the-energy-savings-opportunity-scheme-esos"
              >
                Comply with the Energy Savings Opportunity Scheme
              </a>
            </li>
          </ul>
        </esos-related-content>
      </div>
    </div>

    <div class="govuk-!-margin-bottom-9"></div>
    <esos-back-to-top></esos-back-to-top>
  </div>

  <ng-template #welcome>
    <ng-container [ngSwitch]="vm.status">
      <ng-container *ngSwitchCase="'NO_AUTHORITY'" [ngTemplateOutlet]="apply"></ng-container>
      <ng-container *ngSwitchCase="'ENABLED'" [ngTemplateOutlet]="apply"></ng-container>

      <ng-container *ngSwitchCase="'DISABLED'">
        <ng-container [ngSwitch]="vm.roleType">
          <ng-container *ngSwitchCase="'OPERATOR'" [ngTemplateOutlet]="apply"></ng-container>
          <ng-container *ngSwitchDefault [ngTemplateOutlet]="userDisabled"></ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'ACCEPTED'" [ngTemplateOutlet]="userActivation"></ng-container>
      <ng-container *ngSwitchCase="'TEMP_DISABLED'" [ngTemplateOutlet]="userTempDisabled"></ng-container>
    </ng-container>
  </ng-template>

  <ng-template #userDisabled>
    <esos-page-heading>
      Your user account has been disabled. Please contact your admin to gain access to your account.
    </esos-page-heading>
  </ng-template>

  <ng-template #userActivation>
    <esos-page-heading> Your user account needs to be activated.</esos-page-heading>

    <p class="govuk-body">
      Your user account must be activated before you can sign in to the Manage your Energy Savings Opportunity Scheme
      reporting service.
    </p>

    <p class="govuk-body">
      If your account was created by your regulator, they will now activate your account. You'll receive an email once
      your account has been activated. <a govukLink routerLink="/contact-us">Contact your regulator</a> if your account
      has not been activated after 2 working days.
    </p>
  </ng-template>

  <ng-template #userTempDisabled>
    <esos-page-heading>Lorem ipsum dolor sit amet. Cum molestiae numquam aut impedit quia non illo.</esos-page-heading>
  </ng-template>

  <ng-template #apply>
    <div class="govuk-body">
      <h1 *ngIf="vm.userProfile as user">Welcome {{ user.firstName }} {{ user.lastName }}</h1>

      <ng-container>
        <div *ngIf="vm.installationEnabled === false" class="govuk-body">
          To access the service you'll need to create a new organisation account
        </div>

        <a govukButton routerLink="/accounts/new">Apply for a new organisation account</a>

        <div class="govuk-body govuk-!-font-weight-bold">
          If you want to be added to an existing organisation account
        </div>

        <div class="govuk-body">
          You can request to be added to an existing organisation account by contacting the relevant organisation
          administrator.
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
