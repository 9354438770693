<ng-content *ngIf="rows.length || columns.length !== 0; else detailsContainer"></ng-content>
<ng-template #detailsContainer>
  <ng-container *ngFor="let item of details; let i = index">
    <div *ngIf="item.value" govukSummaryListRow>
      <dt govukSummaryListRowKey>
        <ng-container
          *ngIf="keyTemplate; else bareKey"
          [ngTemplateOutlet]="keyTemplate"
          [ngTemplateOutletContext]="{ $implicit: item, index: i }"
        ></ng-container>
        <ng-template #bareKey>{{ item.key }}</ng-template>
      </dt>
      <dd govukSummaryListRowValue>
        <ng-container
          *ngIf="valueTemplate; else bareValue"
          [ngTemplateOutlet]="valueTemplate"
          [ngTemplateOutletContext]="{ $implicit: item, index: i }"
        ></ng-container>
        <ng-template #bareValue>{{ item.value }}</ng-template>
      </dd>
    </div>
  </ng-container>
</ng-template>
